import React, { useEffect } from "react";
import "./Service.css";
import serviceData from "../../data/servicesData";
import { useNavigate } from "react-router-dom";

const Service = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="astitav_service">
      <div className="astitav_service_graphic_cream-circle"></div>
      <div className="astitav_service_graphic_purple-circle"></div>
      <div className="astitav_service_graphic_red-circle"></div>
      <div className="astitav_service_graphic_aqua-circle"></div>
      <div className="astitav_service_graphic_purple-circle2"></div>
      <div className="astitav_service_graphic_aqua-circle2"></div>
      <div className="astitav_service_graphic_red-circle2"></div>
      <div className="astitav_service_graphic_cream-circle2"></div>

      {serviceData.map((item) => (
        <div
          key={item.id}
          className="astitav_service_card-main"
          onClick={() => {
            // item.id === 15
            //   ? navigate(`/services/16`)
            // :
            navigate(`/services/${item.id}`);
          }}
        >
          <div
            style={{
              backgroundColor:
                item.color === "red"
                  ? "#FFDBD2"
                  : "" || item.color === "green"
                  ? "#E6F9E5"
                  : "" || item.color === "blue"
                  ? "#DFF8FF"
                  : "" || item.color === "orange"
                  ? "#FFEFDF"
                  : "",
            }}
            key={item.id}
            className="astitav_service_card"
          >
            {item.img ? <img src={item.img} alt="" /> : item.icon}
            <div className="astitav_service_card-content-div">
              <p
                style={{
                  color:
                    item.color === "red"
                      ? "#E74C25"
                      : "" || item.color === "green"
                      ? "#5E9F5A"
                      : "" || item.color === "blue"
                      ? "#167287"
                      : "" || item.color === "orange"
                      ? "#F68F29"
                      : "",
                }}
                className="astitav_service_card-title"
              >
                {item.title}
              </p>
              <p className="astitav_service_card-desc">
                {item.desc.slice(0, 300)}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Service;
