import React from "react";
import "./AdmissionSteps.css";

const AdmissionSteps = () => {
  return (
    <div className="astitav_admission-steps">
      <div className="astitav_admission-steps_header">
        <p className="astitav_admission-steps_header-title">Our</p>
        <div className="astitav_admission-steps_header-desc-div">
          <p className="astitav_admission-steps_header-small-title">
            admissions team looks forward to learning about you and your family.
          </p>
          <p className="astitav_admission-steps_header-desc">
            {/* Astitav is a welcoming community that offers a continuous education
            age 2 through Grade 6. We seek to admit students from a variety of
            backgrounds and families who will add richness and diversity to our
            community. */}
            As we believe in transparency, so parents are most welcome to visit
            our place and observe from inside and be the part pf school
            activities.
          </p>
        </div>
      </div>
      <div className="astitav_admission-steps_steps-div">
        <div className="astitav_admission-steps_step">
          <div className="astitav_admission-steps_step-num1">
            <p>01</p>
          </div>
          <p className="astitav_admission-steps_step-title1">First Step</p>
          <p className="astitav_admission-steps_step-desc">
            Visit Astitav during an Open House.
          </p>
        </div>
        <div className="astitav_admission-steps_step">
          <div className="astitav_admission-steps_step-num2">
            <p>02</p>
          </div>
          <p className="astitav_admission-steps_step-title2">Second Step</p>
          <p className="astitav_admission-steps_step-desc">
            Submit an application along with the appropriate fee.
          </p>
        </div>
        <div className="astitav_admission-steps_step">
          <div className="astitav_admission-steps_step-num3">
            <p>03</p>
          </div>
          <p className="astitav_admission-steps_step-title3">Third Step</p>
          <p className="astitav_admission-steps_step-desc">
            Schedule a tour with the Admissions Department.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdmissionSteps;
