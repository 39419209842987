import React from "react";
import "./Believe.css";
import people from "../../assets/images/landing/believe/lady&girl.svg";

import believeVideo from "../../assets/videos/landing/believe.mp4";

import VideoFrame from "../../common/VideoFrame/VideoFrame";

const Believe = () => {
  return (
    <div className="astitav_believe">
      <p className="astitav_believe_title">Astitav</p>
      <div className="astitav_believe_content">
        <VideoFrame video={believeVideo} />
        <div className="astitav_believe_desc1_div">
          <p className="astitav_believe_desc1">
            believe that the
            <br />
            best way
          </p>
          <div className="astitav_believe_desc-div_desktop">
            <div className="astitav_believe_desc-div">
              <p className="astitav_believe_desc2">
                of developing young children is a combination of free
                and structured play, in beautiful and engaging pre school
                learning environments , and a program that constantly develops
                their minds across multiple areas. We know that children develop
                at different rates across different areas.
              </p>
              <button className="astitav_believe_btn">Learn more</button>
            </div>
            <img className="astitav_believe_people-img" src={people} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Believe;
