import React from "react";
import "./Advantages.css";

import pic1 from "../../assets/images/landing/advantages/extra.svg";
import pic2 from "../../assets/images/landing/advantages/sports.svg";
import pic3 from "../../assets/images/landing/advantages/nios.svg";
import pic4 from "../../assets/images/landing/advantages/lifestyle.svg";
import pic5 from "../../assets/images/landing/advantages/safe.svg";
import pic6 from "../../assets/images/landing/advantages/awareness.svg";
// import pic7 from "../../assets/images/landing/advantages/priviledge.svg";
import graphic from "../../assets/images/landing/advantages/graphic.svg";
import people from "../../assets/images/landing/advantages/people.svg";

import { FaUserFriends } from "react-icons/fa";

const Advantages = () => {
  return (
    <div className="astitav_advantages">
      <img className="astitav_advantages_graphic" src={graphic} alt="" />
      <p className="astitav_advantages_title">Advantages</p>
      <div className="astitav_advantages_desc1_div">
        <p className="astitav_advantages_desc1">
          of being a learner of Astitav
        </p>
        <div className="astitav_advantages_desc-div">
          <p className="astitav_advantages_desc2">
            sparks physical growth and discovery while our creative curriculum,
            which combines the traditional and the progressive technique that
            supports each student’s growth.
          </p>
        </div>
      </div>
      <div className="astitav_advantages_div">
        <div className="astitav_advantages_advantage-div">
          <p className="astitav_advantages_number1">
            01
            <img src={pic1} alt="" />
          </p>
          <p className="astitav_advantages_advantage-title">
            Extra- curricular
          </p>
        </div>
        <div className="astitav_advantages_advantage-div">
          {/* <img src={pic2} alt="" /> */}
          <p className="astitav_advantages_number2">
            02
            <img src={pic2} alt="" />
          </p>
          <p className="astitav_advantages_advantage-title">Sports</p>
        </div>
        <div className="astitav_advantages_advantage-div">
          {/* <img src={pic3} alt="" /> */}
          <p className="astitav_advantages_number3">
            03
            <img src={pic3} alt="" />
          </p>
          <p className="astitav_advantages_advantage-title">NIOS</p>
        </div>
        <div className="astitav_advantages_advantage-div">
          {/* <img src={pic4} alt="" /> */}
          <p className="astitav_advantages_number4">
            04
            <img src={pic4} alt="" />
          </p>
          <p className="astitav_advantages_advantage-title">
            Vocational and Lifestyle
          </p>
        </div>
        <div className="astitav_advantages_advantage-div">
          {/* <img src={pic5} alt="" /> */}
          <p className="astitav_advantages_number5">
            05
            <img src={pic5} alt="" />
          </p>
          <p className="astitav_advantages_advantage-title">Safe & Loving</p>
        </div>
        <div className="astitav_advantages_advantage-div">
          {/* <img src={pic6} alt="" /> */}
          <p className="astitav_advantages_number6">
            06
            <img src={pic6} alt="" />
          </p>
          <p className="astitav_advantages_advantage-title">
            Inclusion & Community
            <br />
            Awareness
          </p>
        </div>
        <div className="astitav_advantages_advantage-div">
          {/* <img src={pic7} alt="" /> */}
          <p className="astitav_advantages_number7">
            07
            {/* <img src={pic7} alt="" /> */}
            <FaUserFriends className="astitav_advantages_number7_logo" />
          </p>
          <p className="astitav_advantages_advantage-title">
            Under Priviledge Friendly
          </p>
        </div>
      </div>
      <img className="astitav_advantages_people" src={people} alt="" />
    </div>
  );
};

export default Advantages;
