import React from 'react';
import Women from '../../assets/images/Founder/Woman1.png';
// import Bg from '../../assets/images/Founder/bg.png';
import './message.css'

const Message = () => {
    return (
      <div className='astitav-Founder_container'>
          
          <div className='astitav-Founder'>
              <div className='astitav-Founder_image'>
                  <img
                      src={Women}
                      className="astitav-Founder_image-img"
                      alt=""
                  />
              </div>
              <div className='astitav-Founder_content'>
          
                  <h2 className="astitav-Founder_content-heading">
                  MS. MONICA DINESH
                  </h2>
                  <h3 className="astitav-Founder_content-heading1">
                  DIRECTOR
                  </h3>
                  <p className="astitav-Founder_content-paragraph">
                  “Cultivation of mind should be the ultimate aim of human existence.”- B.R. Ambedkar
                  </p>
                  <p className="astitav-Founder_content-paragraph">
                  The magnitude of discrimination and stigma faced by people and their families with disability in India cannot be underestimated. People do not understand disability, and people fear what they don't understand!
                  </p>
                  <p className="astitav-Founder_content-paragraph">
                  At Astitav, we have classes according to the age groups and functional levels. We keep in mind that our students get the proper socialization and communication opportunity. They get individual as well as group exposure in the school.
                  </p>
                  <p className="astitav-Founder_content-paragraph">
                  We also believe that the only disability in life is a bad attitude, therefore with the right kind of professional support and by helping the children and their families develop a positive attitude, we strive to help children with specific needs become self-reliant and learn how to manage their disability.
                  </p>
                  <p className="astitav-Founder_content-paragraph">
                  At Astitav, we focus on each child’s specific needs and work along with their families to make individual plans that allow us to give specific attention to each child's weaknesses and strengths; setting targets that help the child achieve his fullest potential in the process.
                  </p>
                  <p className="astitav-Founder_content-paragraph">
                  The available infrastructure and the care of professionals at the center, helps ensure that the overall environment at ‘Astitav’ is highly conducive to child’s learning and development.
                  </p>
                  <p className="astitav-Founder_content-paragraph">
                  Apart from working individually with children, ‘Astitav’ also runs various programs and initiatives that help children learn specific vocational skills and undertakes endeavor towards spreading awareness and breaking myths centered on special needs and disabilities.
                  </p>
              </div>
          
          </div>
      </div>
    )
  }

export default Message;