import React, { useEffect } from "react";
import "./Donation.css";
import Header from "../../common/Header/Header";

import believeVideo from "../../assets/videos/landing/believe.mp4";

import VideoFrame from "../../common/VideoFrame/VideoFrame";
import BgCircles from "../../common/BgCircles/BgCircles";

import barcode from "../../assets/images/barcode.jpg";

const Donation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header
        title="Make a Donation"
        paragraph="“Our work becomes possible through your generosity”"
        para2="All donations are aligned under - ATG (U/S-ATG)"
      />

      <div className="astitav_donation-content">
        <BgCircles />
        <div className="astitav_donation">
          <div className="astitav_donation_barcode_div">
            <p className="astitav_donation_barcode_small-title">
              Pay Using UPI
            </p>
            <p className="astitav_donation_barcode_title">ASTITAV</p>
            <img src={barcode} className="astitav_donation_barcode" alt="" />
            <p className="astitav_donation_barcode_id">
              UPI ID - astitav.ace@kotak
            </p>
          </div>
          <p className="astitav_donation_title">
            Wire funds using the following bank account details:
          </p>
          <div className="astitav_donation_content">
            <VideoFrame video={believeVideo} />
            <div className="astitav_donation_desc1_div">
              <p className="astitav_donation_desc1">CURRENT ACCOUNT</p>
              <div className="astitav_donation_desc-div_desktop">
                <div className="astitav_donation_account-details-div">
                  <div className="astitav_donation_detail">
                    <span>●</span>
                    <p>
                      Name of the NGO as it appears in Bank Records: ASTITAV
                    </p>
                  </div>
                  <div className="astitav_donation_detail">
                    <span>●</span>
                    <p>Account No: 6911930917</p>
                  </div>
                  <div className="astitav_donation_detail">
                    <span>●</span>
                    <p>Bank Name: Kotak Mahindra Bank</p>
                  </div>
                  <div className="astitav_donation_detail">
                    <span>●</span>
                    <p>IFSC Code: KKBK0005040</p>
                  </div>
                  <div className="astitav_donation_detail">
                    <span>●</span>
                    <p>
                      Branch: Paramount Flora Ville Sector 137 , Noida, Uttar
                      Pradesh
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="astitav_donation_barcode_div">
            <p className="astitav_donation_barcode_small-title">
              Pay Using UPI
            </p>
            <p className="astitav_donation_barcode_title">ASTITAV</p>
            <img src={barcode} className="astitav_donation_barcode" alt="" />
            <p className="astitav_donation_barcode_id">
              UPI ID - astitav.ace@kotak
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Donation;
