import React from "react";
import "./Catering.css";

import intervention from "../../assets/images/landing/catering/intervention.jpg";
import flexcare from "../../assets/images/landing/catering/flexcare.jpg";
import preschool from "../../assets/images/landing/catering/preschool.jpg";
import readyness from "../../assets/images/landing/catering/readyness.jpg";
import vocational from "../../assets/images/landing/catering/vocational.jpg";

const Catering = () => {
  const cardData = [
    {
      id: 1,
      title: "Early Intervention",
      img: intervention,
      desc: "Your child will get absurted gradually, main stream society, regular school program.",
      age_time: "2-5 years",
      color: "#E74C25",
      bgColor: "#FFDBD2",
    },
    {
      id: 2,
      title: "School Readiness",
      img: readyness,
      desc: "Therapeutic interventions for the maximum growth",
      age_time: "4-6 years",
      color: "#167287",
      bgColor: "#DFF8FF",
    },
    {
      id: 3,
      title: "OBE/NIOS",
      img: preschool,
      desc: "Programme providing an elementary education at three levels, for school drop-outs and neo-literates.",
      age_time: "7+ years",
      color: "#F68F29",
      bgColor: "#FFEFDF",
    },
    {
      id: 4,
      title: "Vocational Training",
      img: vocational,
      desc: "Trains your child about the skill training, concept of a job and job related functional academics",
      age_time: "16+ years",
      color: "#5E9F5A",
      bgColor: "#E6F9E5",
    },
    {
      id: 5,
      title: "Remedial Teaching",
      img: flexcare,
      desc: "For them we have specially designed programs to meet their academic needs and practical life also.",
      age_time: "",
      color: "#167287",
      bgColor: "#EEF1FF",
    },
  ];
  return (
    <div className="astitav_catering">
      <p className="astitav_catering_title">We</p>
      <p className="astitav_catering_desc">are catering needs for</p>
      <div className="astitav_catering_cards-div">
        {cardData.map((item) => (
          <div
            key={item.id}
            className="astitav_catring_card"
            style={{ backgroundColor: item.bgColor }}
          >
            <p
              style={{ color: item.color }}
              className="astitav_catring_card-title"
            >
              {item.title}
            </p>
            <img className="astitav_catring_card-img" src={item.img} alt="" />
            <p className="astitav_catring_card-desc">{item.desc}</p>
            <p
              style={{ color: item.color }}
              className="astitav_catring_card-age-time"
            >
              {item.age_time}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Catering;
