import React from "react";
import Whopic from "../../assets/images/About/History/whopic.png";
import "./who.css";

const Who = () => {
  return (
    <div className="astitav-about-who_container">
      <div className="astitav-about-who_container-card">
        <div className="astitav-about-who_container-card_content">
          <h2 className="astitav-about-who_container-card_content-heading">
            Who we are?
          </h2>
          <p className="astitav-about-who_container-card_content-paragraph">
            Astitav is a social service organization that aims to empower
            persons with disabilities to thrive in inclusive communities. We
            serve children aged 12 months to 25 years, as you know learning has
            no age.
            {/* with a diverse range of moderate to severe developmental
            needs including autism, Down Syndrome, Cerebral Palsy, Learning
            Disabilities, various Intellectual Disabilities, physical, visual
            and/or hearing disabilities; many have multiple disabilities. */}
          </p>
          <p className="astitav-about-who_container-card_content-paragraph">
            {/* In ACE we cater the needs of the infants, toddlers, children and
            youths in the Early Intervention Programme and Special Education
            Programme. We also support siblings and as well as their families.
            We also provide training and consultancy services for the public,
            private and social service sectors. */}
          </p>
        </div>
      </div>
      <div className="astitav-about-who_container-image">
        <img
          src={Whopic}
          className="astitav-about-who_container-image-img"
          alt=""
        />
      </div>
    </div>
  );
};

export default Who;
