import React from "react";
import "./HowToApply.css";

import iBtn from "../../assets/images/apply/iBtn.svg";
import cactus from "../../assets/images/apply/cactus.svg";
import date from "../../assets/images/apply/date.svg";
import BgCircles from "../../common/BgCircles/BgCircles";

const HowToApply = () => {
  return (
    <div className="astitav_how-to-apply">
      <div className="astitav_how-to-apply_cards-div">
        <div className="astitav_how-to-apply_card">
          <img src={iBtn} alt="" />
          <p className="astitav_how-to-apply_card-title">Request Info</p>
          <p className="astitav_how-to-apply_card-desc">
            Learn more about Astitav’s curriculum, community, and application
            process.
          </p>
        </div>
        <div className="astitav_how-to-apply_center-card">
          <img src={cactus} alt="" />
          <p className="astitav_how-to-apply_card-title">Apply Today</p>
          <p className="astitav_how-to-apply_card-desc">
            Get ahead of the game by completing your application today, and we
            will call you to schedule your tour.
          </p>
        </div>
        <div className="astitav_how-to-apply_card">
          <img src={date} alt="" />
          <p className="astitav_how-to-apply_card-title">Schedule a Tour</p>
          <p className="astitav_how-to-apply_card-desc">
            Learn more by scheduling a visit and tour with our Admissions team.
          </p>
        </div>
      </div>
      <div className="astitav_how-to-apply_steps-div">
        <BgCircles />
        <div className="astitav_how-to-apply_step">
          <div className="astitav_how-to-apply_step-num1">
            <p>01</p>
          </div>
          <p className="astitav_how-to-apply_step-title1">School tour</p>
          <p className="astitav_how-to-apply_step-desc">
            Sign up on our website
          </p>
        </div>
        <div className="astitav_how-to-apply_step">
          <div className="astitav_how-to-apply_step-num2">
            <p>02</p>
          </div>
          <p className="astitav_how-to-apply_step-title2">Application</p>
          <p className="astitav_how-to-apply_step-desc">
            Found in your Astitav checklist
          </p>
        </div>
        <div className="astitav_how-to-apply_step">
          <div className="astitav_how-to-apply_step-num3">
            <p>03</p>
          </div>
          <p className="astitav_how-to-apply_step-title3">Parent/Child visit</p>
          <p className="astitav_how-to-apply_step-desc">
            Includes classroom observation
          </p>
        </div>
      </div>
      <form className="astitav_how-to-apply_form">
        <div className="astitav_how-to-apply_form-sections-div">
          <div className="astitav_how-to-apply_parent-form">
            <p className="astitav_how-to-apply_form-title">Parent details</p>
            <div className="astitav_how-to-apply_form-input-div">
              <label>
                Name , Surname <span>*</span>
              </label>
              <input type="text" />
            </div>
            <div className="astitav_how-to-apply_form-input-div">
              <label>
                Phone Number <span>*</span>
              </label>
              <input type="text" />
            </div>
            <div className="astitav_how-to-apply_form-input-div">
              <label>
                Email <span>*</span>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className="astitav_how-to-apply_child-form">
            <p className="astitav_how-to-apply_form-title">Child details</p>
            <div className="astitav_how-to-apply_form-input-div">
              <label>
                Age of first child <span>*</span>
              </label>
              <input type="text" />
            </div>
            <div className="astitav_how-to-apply_form-input-div">
              <label>
                Class <span>*</span>
              </label>
              <input type="text" />
            </div>
            <div className="astitav_how-to-apply_form-input-div">
              <label>
                Comments <span>*</span>
              </label>
              <input type="text" />
            </div>
          </div>
        </div>
        <p className="astitav_how-to-apply_form-agree-tick">
          <input type="checkbox" />I agree to the processing of personal data
        </p>
        <button>Submit my information</button>
      </form>
      <form className="astitav_how-to-apply_child-form"></form>
    </div>
  );
};

export default HowToApply;
