import React from "react";
import Women from "../../assets/images/Founder/Woman3.png";
import Women1 from "../../assets/images/Founder/Woman2.png";
import Man from "../../assets/images/Founder/Man1.png";
// import Bg from '../../assets/images/Founder/bg.png';
import "./advisor.css";

const Advisor = () => {
  return (
    <div className="astitav-advisor_main-div">
      <div className="astitav-advisor_container">
        <div className="astitav-advisor">
          <div className="astitav-advisor_image">
            <img src={Women1} className="astitav-advisor_image-img" alt="" />
          </div>
          <div className="astitav-advisor_content">
            <h2 className="astitav-advisor_content-heading">
              DR. JAYANTI PUJARI
            </h2>
            <h3 className="astitav-advisor_content-heading1">
              Professor & Director at Amity Institute of Rehabilitation Sciences
            </h3>
            <p className="astitav-advisor_content-paragraph">
              Dr. Jayanti Pujari at present is a Professor and Director at Amity
              Institute of Rehabilitation Sciences, Amity University, Uttar
              Pradesh. Before joining Amity University, Dr. Pujari has held
              distinct positions in the field of education and has served the
              unprivileged strata of the society, with reference to children
              with special needs. With vast experience and expertise of 28 years
              , Dr. Pujari , a reputed educationist and academician holds major
              responsibilities that include academic and administrative
              leadership, nurturing innovations and inspire faculty and students
              to engage in cutting edge research and innovations of vital value
              to uplift the special needs society. This has been possible
              through dynamic transaction of meticulously designed curricula.
              The transaction of curriculum includes lectures, discussions,
              national and international seminars , practicals, on the job
              training, course assignments and project assignments. Her areas of
              interest are Special Education, Inclusive Education ,
              Rehabilitation Sciences , Research Methodology , Psychology and
              Teaching-Learning Process.
            </p>
          </div>
        </div>

        <div className="astitav-advisor1">
          <div className="astitav-advisor_image">
            <img src={Women} className="astitav-advisor_image-img" alt="" />
          </div>
          <div className="astitav-advisor_content">
            <h2 className="astitav-advisor_content-heading">
              MS. SHEELU MATHEW
            </h2>
            <h3 className="astitav-advisor_content-heading1">Principal</h3>
            <p className="astitav-advisor_content-paragraph">
              Ms. Sheelu Mathew was sent by St. Mary’s Education Society to set
              up St. Mary’ School, Dwarka in January 1995. She worked as Manager
              while at the same time teaching primary classes. She was
              thereafter appointed as Headmistress in July 1996. Subsequently as
              the School grew she taught the higher classes too while at the
              same time managing the administration of the School. Presently she
              heads a team of 120 teachers and 35 support staff. Today St.
              Mary’s is one of the leading public schools in the Dwarka subcity.
            </p>
          </div>
        </div>
      </div>

      <div className="astitav-advisor_container">
        <div className="astitav-advisor">
          <div className="astitav-advisor_image">
            <img src={Man} className="astitav-advisor_image-img" alt="" />
          </div>
          <div className="astitav-advisor_content">
            <h2 className="astitav-advisor_content-heading">RAKESH DHASMANA</h2>
            <h3 className="astitav-advisor_content-heading1">
              Founder, CEO at AIMS Media Pvt.Ltd
            </h3>
            <p className="astitav-advisor_content-paragraph">
              Rakesh Dhasmana is an Educationist and Social Entrepreneur. He is
              also an Advisor and Technical Consultant to number of Educational
              Institutions, Corporates and Social Organisations. He has a global
              presence because of his number of contributions, achievements and
              commendations. New business development (start-ups), Team
              building, Strategic planning and Management consulting are his
              core expertise.
            </p>
          </div>
        </div>

        <div className="astitav-advisor1">
          <div className="astitav-advisor_image">
            <img src={Women} className="astitav-advisor_image-img" alt="" />
          </div>
          <div className="astitav-advisor_content">
            <h2 className="astitav-advisor_content-heading">
              DR. (MRS.) HEMLATA
            </h2>
            <h3 className="astitav-advisor_content-heading1">
              Director in charge
            </h3>
            <p className="astitav-advisor_content-paragraph">
              Working in National Centre for Disability Studies, IGNOU as
              Director in Charge.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Advisor;
