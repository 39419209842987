import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Navbar from "./common/Navbar/Navbar";
import Footer from "./common/Footer/Footer";
import About from "./pages/About/About";
import Landing from "./pages/Landing/Landing";
import Founder from "./pages/FounderMessage/Founder";
import Advisors from "./pages/Advisors/Advisors";
import Contact from "./pages/Contact/Contact";
import Services from "./pages/Services/Services";
import Apply from "./pages/HowToApply/Apply";
import ServiceDetail from "./pages/ServiceDetail/ServiceDetail";
import Admission from "./pages/Admission/Admission";
import Parents from "./pages/ForOurParents/Parents";
import Gallery from "./pages/OurGallery/Gallery";
import Donation from "./pages/MakeADonation/Donation";
import Internship from "./pages/InternshipForm/Internship";
import Volunteer from "./pages/BecomeaVolunteer/Volunteer";
import AstitavInSchools from "./pages/AstitavInSchools/AstitavInSchools";
import B2B from "./pages/B2B/B2B";

const App = () => {
  const NavOutlet = () => {
    return (
      <>
        <Navbar />
        <Outlet />
      </>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route element={<NavOutlet />}>
          <Route path="/about-us" element={<About />} />
          <Route path="/founder" element={<Founder />} />
          <Route path="/advisor" element={<Advisors />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:id" element={<ServiceDetail />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/how-to-apply" element={<Apply />} />
          <Route path="/admission" element={<Admission />} />
          <Route path="/for-our-parents" element={<Parents />} />
          <Route path="/our-gallery" element={<Gallery />} />
          <Route path="/donation" element={<Donation />} />
          <Route path="/internship" element={<Internship />} />
          <Route path="/volunteer" element={<Volunteer />} />
          <Route path="/astitav_in_schools" element={<AstitavInSchools />} />
          <Route path="/bridge-to-brilliance" element={<B2B />} />
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
