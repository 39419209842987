import React from "react";
import Header from "../../common/Header/Header";
import History from "../../components/History/History";
import Why from "../../components/AboutWhy/Why";
import Who from "../../components/AboutWho/Who";

const About = () => {
  return (
    <div>
      <Header
        title="About Us"
        paragraph="We help children engage their bodies and minds through fun and educational activities, we have classes according to the age groups and functional levels. We keep in mind that our students get the proper socialization and communication opportunity. They get individual as well as group exposure in the school."
      />
      <History />
      <Why />
      <Who />
    </div>
  );
};

export default About;
