import React from "react";
import Header from "../../common/Header/Header";

const AstitavInSchools = () => {
  return (
    <div>
      <Header
        title="Astitav in Schools"
        paragraph="Ace Supports to the regular schools to be equipped for inclusive education and catering the needs of childreb with Autism, ATHD, learning disablities and other related different needs."
        school1="Delhi Gold Public School"
        school2="Indrapuram Public School"
        school3="SD Vidya School"
      />
    </div>
  );
};

export default AstitavInSchools;
