import React from "react";
import Header from "../../common/Header/Header";
import HowToApply from "../../components/HowToApply/HowToApply";

const Apply = () => {
  return (
    <div>
      <Header
        title="How to Apply"
        paragraph="Welcome to Astitav Centre for Empowerment. We look forward to getting to know you, your child and your family."
      />
      <HowToApply />
    </div>
  );
};

export default Apply;
