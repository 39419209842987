import React, { useEffect } from "react";
import "./Contact.css";
import Header from "../../common/Header/Header";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header
        title="Contact Us"
        paragraph="If you have any questions or inquiries please feel free to contact."
      />
      <div className="astitav_contact">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.1352991542594!2d77.365555!3d28.5356527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce71107405611%3A0x283e653ad2f3503a!2sAstitav%20Centre%20for%20Empowerment!5e0!3m2!1sen!2sin!4v1691489309226!5m2!1sen!2sin"
          className="astitav_contact_map"
        />
        <form className="astitav_contact_form">
          <p className="astitav_contact_form_heading">Schedule a tour</p>
          <div className="astitav_contact_form_double-input-div">
            <input type="text" placeholder="First Name" />
            <input type="text" placeholder="Last Name" />
          </div>
          <div className="astitav_contact_form_double-input-div">
            <input type="text" placeholder="Email" />
            <input type="text" placeholder="Phone Number" />
          </div>
          <div className="astitav_contact_form_single-input-div">
            <label>When you want to start?</label>
            <input type="text" placeholder="Unsure at this time" />
          </div>
          <div className="astitav_contact_form_single-input-div">
            <label>What is your child's birth month and year?</label>
            <input type="text" placeholder="Date" />
          </div>
          <div className="astitav_contact_form_single-input-div">
            <label>
              Submit your information below and we'll send you an email soon.
              We're excited to meet you!
            </label>
            <input
              type="text"
              placeholder="Is there anything we should know about your family?"
            />
          </div>
          <button>Submit my information</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
