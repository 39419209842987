import React from "react";
import LandingHero from "../../components/LandingHero/LandingHero";
import Believe from "../../components/Believe/Believe";
import Advantages from "../../components/Advantages/Advantages";
import Catering from "../../components/Catering/Catering";
import Therapies from "../../components/Therapies/Therapies";
// import News from "../../components/News/News";
import VisitUs from "../../components/VisitUs/VisitUs";

import people from "../../assets/images/visitus/people.svg";

const Landing = () => {
  return (
    <div>
      <LandingHero />
      <Believe />
      <Advantages />
      <Catering />
      <Therapies />
      {/* <News /> */}
      <VisitUs img={people} />
    </div>
  );
};

export default Landing;
