import React from "react";
import Header from "../../common/Header/Header";
import Service from "../../components/Service/Service";

const Services = () => {
  return (
    <div>
      <Header
        title="Our Services"
        paragraph="In Astitav we have classes according to the age groups and functional levels. We keep in mind that our students get the proper socialization and communication opportunity. They get individual as well as group exposure in the school."
      />
      <Service />
    </div>
  );
};

export default Services;
