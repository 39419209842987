import React from "react";
import "./BgCircles.css";

const BgCircles = () => {
  return (
    <div className="astitav_color-circles-div">
      <div className="astitav_color-circles-cream"></div>
      <div className="astitav_color-circles-red"></div>
      <div className="astitav_color-circles-aqua"></div>
      <div className="astitav_color-circles-aqua2"></div>
      <div className="astitav_color-circles-purple"></div>
      <div className="astitav_color-circles-green"></div>
    </div>
  );
};

export default BgCircles;
