import img1 from "../assets/images/service/intervention.svg";
import img2 from "../assets/images/service/estp.svg";
import img3 from "../assets/images/service/nios.svg";
import img4 from "../assets/images/service/curricular.svg";
import img5 from "../assets/images/service/vocational.svg";
import img6 from "../assets/images/service/speech.svg";
import img7 from "../assets/images/service/occupational.svg";
import img8 from "../assets/images/service/parent.svg";
import img9 from "../assets/images/service/physio.svg";
import img10 from "../assets/images/service/physchology.svg";
import img11 from "../assets/images/service/music.svg";
import img12 from "../assets/images/service/ppmd.svg";
import img13 from "../assets/images/service/other.svg";

import early from "../assets/images/serviceDetail/early.svg";
import art from "../assets/images/serviceDetail/art.svg";
import asd from "../assets/images/serviceDetail/asd.svg";
import occupational from "../assets/images/serviceDetail/occupational.svg";
import parent from "../assets/images/serviceDetail/parent.svg";
import physio from "../assets/images/serviceDetail/physio.svg";
import ppmd from "../assets/images/serviceDetail/ppmd.svg";
import psyco from "../assets/images/serviceDetail/psyco.svg";
import speech from "../assets/images/serviceDetail/speech.jpg";
import vocational from "../assets/images/serviceDetail/vocational.jpg";
import curricular from "../assets/images/serviceDetail/curricular.jpg";

import counselling from "../assets/images/serviceDetail/other/counselling.svg";
import parentTeacher from "../assets/images/serviceDetail/other/parent-teacher.svg";
import parentSupport from "../assets/images/serviceDetail/other/parentSupport.svg";
import sibling from "../assets/images/serviceDetail/other/sibling.svg";
import dayCare from "../assets/images/serviceDetail/other/dayCare.jpg";

import { FaHands, FaRegPlayCircle } from "react-icons/fa";
import { GiPaintedPottery } from "react-icons/gi";
import { BiRun } from "react-icons/bi";
import { TbSocial } from "react-icons/tb";

const serviceData = [
  {
    id: 1,
    title: "OBE/NIOS",
    detailTitle: "NIOS/OBE",
    desc: "Astitav understands that students in their adolescence face various issues related to emotion, social and vocational aspects. The students are either drop outs or at the stage when there is hardly any chance of being included in the regular school but we have option to include them in the society.",
    color: "blue",
    img: img3,
    // detailImg:
  },
  {
    id: 2,
    title: "Academic Intervention",
    detailTitle: "Academic Intervention",
    desc: "Academic interventions are additional instruction and supports that supplements the general curriculum (regular classroom instruction) and are necessary to improve academic performance for some students.",
    color: "red",
    img: img1,
  },
  {
    id: 3,
    title: "Remedial Teaching",
    detailTitle: "Remedial Teaching",
    desc: "Remediation, or remedial teaching consists of tailor-made teaching strategies designed to meet the unique learning needs of your child. Remediation is the most powerful teaching method to strengthen areas of learning needs by teaching to your child's strengths.",
    color: "green",
    img: img2,
  },
  {
    id: 4,
    title: "PPMD",
    detailTitle: "PROGRAMME FOR PUPILS WITH MULTIPLE DISABILITIES (PPMD)",
    desc: "Programme for Pupils with Multiple Disabilities (PPMD) caters to children aged 7 years to 18 years old with developmental disabilities or multiple disabilities, which may include a combination of intellectual, physical, visual and/or hearing impairments.",
    color: "blue",
    img: img12,
    detailImg: ppmd,
    about: (
      <div className="astitav_service-about_content_text-div">
        <p className="astitav_service-about_content_points-heading">
          All children have an Individualized Education Plan (IEP), which covers
          the following six different domains:
        </p>
        <div className="astitav_service-about_content_points">
          <p>
            <span>●</span>
            Language and Communication Skills
          </p>
          <p>
            <span>●</span>
            Cognitive Skills
          </p>
          <p>
            <span>●</span>
            Social and Emotional Skills
          </p>
          <p>
            <span>●</span>
            Fine Motor Skills
          </p>
          <p>
            <span>●</span>
            Gross Motor Skills
          </p>
          <p>
            <span>●</span>
            Adaptive Daily Living Skills
          </p>
        </div>
        <p className="astitav_service-about_content_desc">
          As part of our efforts to tailor an IEP to individual needs, emphasis
          on the various domains will be different for each child. For example,
          greater emphasis is placed on cognitive skills for high functioning
          children, whereas more focus will be given on adaptive living and
          pre-vocational skills for those referred to Day Activity Centers and
          Sheltered Workshops. For the more severely disabled, priority is given
          to functional skills so that they can become as independent as
          possible in the community.
        </p>
      </div>
    ),
  },
  {
    id: 5,
    title: "Day Care",
    detailTitle: "DAY CARE",
    desc: "Your child's earliest experiences will play a big part in their development. They will build the foundation for your child's future success. Most of these experiences are the early home parenting and nurturing experiences that you give your child. But other kinds of experiences, like day care, are also a developmental influence.",
    color: "blue",
    detailImg: dayCare,
    icon: <FaHands className="astitav_service_card_icon" />,
    about: (
      <div className="astitav_service-about_content_text-div">
        <p className="astitav_service-about_content_points-heading">
          The most important benefit of choosing a day care is the quality
          provided by a stellar day care. The needs of your child depend on
          their age, but a high-quality day care will have certain
          characteristics. This includes caregiving that is responsive and
          sensitive, stimulates the senses, and has a safe, healthy environment.
          A high-quality day care has long-term benefits like:
        </p>
        <div className="astitav_service-about_content_points">
          <p>
            <span>●</span>
            Increased language development
          </p>
          <p>
            <span>●</span>
            Increased cognitive abilities
          </p>
          <p>
            <span>●</span>
            Development of good peer relationships
          </p>
          <p>
            <span>●</span>
            Decreased incidents with the caregivers
          </p>
        </div>
      </div>
    ),
  },
  {
    id: 6,
    title: "Sports/Motor Play Therapy",
    detailTitle: "SPORTS/MOTOR PLAY THERAPY",
    desc: "Occupational therapists focus on fine motor skills, which are the movements of the small muscles of the body. A physical therapist focuses more on gross motor skills, which are movements using the large muscles of the body.",
    color: "red",
    icon: (
      <FaRegPlayCircle
        className="astitav_service_card_icon"
        style={{ backgroundColor: "pink" }}
      />
    ),
  },
  {
    id: 7,
    title: "Pottery",
    detailTitle: "POTTERY",
    desc: "Pottery is a fascinating art form that allows children to explore their creativity and develop essential skills. Pottery classes for kids provide a nurturing environment where young minds can learn the techniques of working with clay and create beautiful masterpieces.",
    color: "green",
    icon: (
      <GiPaintedPottery
        className="astitav_service_card_icon"
        style={{ backgroundColor: "orange" }}
      />
    ),
  },
  {
    id: 8,
    title: "Outing & Community Visits",
    detailTitle: "OUTING & COMMUNITY VISITS",
    desc: "For individuals with autism and their parents, community outings can become a source of anxiety and frustration. Unfortunately, outings often come with over-stimulation, full-scale meltdowns, and disapproving looks from strangers. Because of this, parents often steer clear of public outings, and children miss out on the diverse learning opportunities that come from public settings. Community outings give those with autism the chance to practice socialization, physical awareness, and promote generalization of skills in the “real world.”",
    color: "blue",
    icon: (
      <BiRun
        className="astitav_service_card_icon"
        style={{ backgroundColor: "gray" }}
      />
    ),
  },
  {
    id: 9,
    title: "ADL-IDL",
    detailTitle: "ACTIVITIES FOR DAILY LIVING INDEPENDENT LIVING",
    desc: "Activities of daily living include activities surrounding personal hygiene, meal preparation, and money and time management. The benefits of adaptive behavior extend to individuals and their communities, but some children may face challenges in developing ADLs and may require additional support.",
    color: "red",
    img: img2,
  },
  {
    id: 10,
    title: "Social & Communicational Skills",
    detailTitle: "SOCIAL & COMMUNICATIONAL SKILLS",
    desc: "This skill employs the use of facial expressions or body language, eye contact, and knowing the appropriate distance to be from someone when talking to them. It is important to teach your child to also know how to understand the person with whom they are communicating.",
    color: "green",
    icon: (
      <TbSocial
        className="astitav_service_card_icon"
        style={{ backgroundColor: "red" }}
      />
    ),
  },
  {
    id: 11,
    title: "Music & Art Therapy (AM2D) ",
    detailTitle: "MUSIC & ART THERAPY (AM2D)",
    desc: "Music Therapy intervention is based on customized therapeutic goals during the weekly half-hour sessions, during which the children are involved individually or in a group of two to six children.",
    color: "blue",
    img: img11,
    detailImg: art,
    about: (
      <div className="astitav_service-about_content_text-div">
        <p className="astitav_service-about_content_desc">
          Art Therapy intervention offers the children an opportunity to work
          around emotional obstacles that are impeding their educational
          progress, facilitating their communication and imagination through
          artworks and the sharing of thoughts and feelings. It aims to help
          them relieve stress, reduce anxiety, facilitate appropriate social
          behaviour and improve their outlook on life. Through practice,
          physically challenged children can also develop mastery of tools,
          materials, and processes.
        </p>
        <p className="astitav_service-about_content_desc">
          Music Therapy intervention is based on customized therapeutic goals
          during the weekly half-hour sessions, during which the children are
          involved individually or in a group of two to six children. Their
          goals include increasing eye contact and on-seat behavior,
          vocalization, decision-making, social skills, and developing
          initiation.
        </p>
      </div>
    ),
  },
  {
    id: 12,
    title: "Early Intervention Programme",
    detailTitle: "EARLY INTERVENTION PROGRAMME (EIP)",
    desc: "Early Intervention Programme for Infants and Young Children (EIP) aims to give children aged 12 months to 6 years old, with either Down syndrome, developmental delay, cerebral palsy or multiple disabilities, a head start in their development.",
    color: "red",
    img: img1,
    detailImg: early,
    about: (
      <div className="astitav_service-about_content_text-div">
        <p className="astitav_service-about_content_points-heading">
          There are three levels in EIP:
        </p>
        <div className="astitav_service-about_content_points">
          <p>
            <span>●</span>
            Infant Stimulation
          </p>
          <p>
            <span>●</span>
            Motor Training
          </p>
          <p>
            <span>●</span>
            Special Education
          </p>
        </div>
        <p className="astitav_service-about_content_desc">
          Depending on the level of the child, EIP aims to facilitate the
          development of gross motor skills, fine motor skills, perceptual
          cognitive skills, language and communication skills, socialization
          skills and self-help skills. Children in this program are exposed to
          age appropriate concepts at their level of comprehension and
          functioning. The methodology employed is differentiated as per each
          child’s need. Therapy and Concept development go hand in hand.
          Pre-requisite learning skills are worked upon in all areas such as
          Language, Math and Skill Development. The Social and Emotional needs
          of each child are met through our Play Way methodology. Children are
          taught through Music, Art and Games to develop Gross Motor and Fine
          Motor Skills.
        </p>
      </div>
    ),
  },
  {
    id: 13,
    title: "ESTP For ASD",
    detailTitle: "EARLY STRUCTURED TEACHING PROGRAMME FOR ASD",
    desc: "Introducing in March 2018 as the first structured programme for children with Autism Spectrum Disorder (ASD) in Early Structured Teaching for ASD (ESTP) serves children aged 2 years to 6 years old from low, moderate to high function.",
    color: "green",
    img: img2,
    detailImg: asd,
    about: (
      <div className="astitav_service-about_content_text-div">
        <p className="astitav_service-about_content_desc">
          ESTP aims to understand and appreciate the nature of children with
          autism. It aims to provide a positive learning environment and a
          differentiated curriculum to equip each child with the necessary
          functional and academic skills to help him/her achieve his/her fullest
          potential so that he/she can lead an independent and productive life,
          one that is integrated with his/her family and the community.
        </p>
        <p className="astitav_service-about_content_points-heading">
          Children are grouped according to their individual skills, strengths
          and interests. The structured teaching curriculum focuses on the
          functional skills and building skills in the following domains:
        </p>
        <div className="astitav_service-about_content_points">
          <p>
            <span>●</span>
            Language and Communication Skills
          </p>
          <p>
            <span>●</span>
            Cognitive Skills
          </p>
          <p>
            <span>●</span>
            Social and Emotional Skills
          </p>
          <p>
            <span>●</span>
            Fine Motor Skills
          </p>
          <p>
            <span>●</span>
            Gross Motor Skills
          </p>
          <p>
            <span>●</span>
            Adaptive Daily Living Skills
          </p>
        </div>
        <p className="astitav_service-about_content_desc">
          Although autism is a lifelong disability, early intervention,
          consistent teaching and support help children to manage the demands
          faced in their daily living.
        </p>
      </div>
    ),
  },
  {
    id: 14,
    title: "Extra Curricular & Co-Curricular",
    detailTitle: "Extra Curricular & Co-Curricular",
    desc: "Extracurricular activities are an essential part of a well-rounded education. These activities enable students to apply academic skills in a real-world context.They also allow students to reinforce the lessons learned in the classroom.",
    color: "blue",
    img: img4,
    detailImg: curricular,
  },
  {
    id: 15,
    title: "The Pre-Vocational & Vocational",
    detailTitle: "THE PRE-VOCATIONAL & VOCATIONAL",
    desc: "Program aims at addressing the growing needs of the children. It has a differentiated approach whereby the students are groomed for development of a career and vocational skills, along with the ability to live independently.",
    color: "orange",
    img: img5,
    detailImg: vocational,
    about: (
      <div className="astitav_service-about_content_text-div">
        <p className="astitav_service-about_content_desc">
          The curriculum is functional in nature and designed to impart
          practical knowledge to the students. The curriculum comprises of
          functional language and math, community living skills, independent
          living skills and computers. The students are exposed to various life
          skills, office skills, grooming and hygiene, cooking, packaging skills
          and art and craft.
        </p>
        <p className="astitav_service-about_content_desc">
          These important skills not only gear them up for the future but also
          help develop them holistically.
        </p>
        <p className="astitav_service-about_content_desc">
          The children in these programmes are taught concepts that are
          chronologically appropriate. These concepts are modified to meet each
          child’s level of functioning. Formal skill development is worked upon
          through the year. Reading, Writing and Math form the Basis of the
          Curriculum. ADL Skills (Activities of Daily Living) are also given
          prime importance and are focused upon daily to equip the children with
          skills to bring about independence. Our therapy program assists the
          children in their journey to realize the goals set for them. Our
          Co-curricular program ensures Social Skills are enhanced and the
          Psychological and Emotional needs of each child are met with.
          Activities offered include Dance, Music, Art, Gym and Games.
        </p>
      </div>
    ),
  },
  {
    id: 16,
    title: "Speech Therapy",
    detailTitle: "SPEECH AND LANGUAGE THERAPY",
    desc: "Speech and Language Therapy accesses and addresses the child ‘s needs vis-a-vis their developmental milestones, before appropriate intervention goals are set and carried out.",
    color: "green",
    img: img6,
    detailImg: speech,
    about: (
      <div className="astitav_service-about_content_text-div">
        <p className="astitav_service-about_content_desc">
          Speech and language intervention may involve developing basic
          communication or pre-linguistic skills, verbal comprehension, spoken
          vocabulary, sentence expansion, articulation, voice and a more
          efficient respiratory system. For some children, an augmentative
          communication (AAC) system may be introduced to enhance overall
          communication. The Speech and Language Therapists also address the
          development of appropriate promotor and feeding skills, and the
          maintenance of safe swallowing habits and good oral hygiene for the
          child.
        </p>
        <p className="astitav_service-about_content_desc">
          To achieve holistic intervention, the Speech and Language therapists
          work closely with parents, teachers and fellow professionals, and
          collaborate with various medical and allied health professionals.
        </p>
      </div>
    ),
  },
  {
    id: 17,
    title: "Occupational Therapy",
    detailTitle: "OCCUPATIONAL THERAPY",
    desc: "Occupational Therapy enables children to participate and find their potential in everyday activities at home, in school and in community environments.",
    color: "blue",
    img: img7,
    detailImg: occupational,
    about: (
      <div className="astitav_service-about_content_text-div">
        <p className="astitav_service-about_content_desc">
          The Occupational Therapists assess the child’s abilities that are
          vital to such activities. These skills include balance, muscle tone
          and strength, body awareness, fine and gross motor skills, motor
          planning, visual perception and visual motor integration, sensory
          integration and behaviour. They are also involved in recommending
          adaptive strategies, equipment and technology that will facilitate
          independence in everyday living.
        </p>
        <p className="astitav_service-about_content_desc">
          To maximize the child’s potential, the Occupational Therapists work
          closely with parents and teachers on how to assist the child in
          classrooms and at home and collaborate with the various allied health
          professionals to enhance the child’s quality of life.
        </p>
      </div>
    ),
  },
  {
    id: 18,
    title: "Parent Support Group",
    detailTitle: "PARENT SUPPORT GROUP (SANSARG)",
    desc: "We are formulating Sansarg – a parent support group as we believe that interaction with other parents is one of the best ways to offer support in times of isolation and despair of parenting a child with special needs.",
    color: "orange",
    img: img8,
    detailImg: parent,
    about: (
      <div className="astitav_service-about_content_text-div">
        <p className="astitav_service-about_content_desc">
          Sansarg will provide a safe place for parents to express their
          feelings openly in a non-judgmental environment, where they can share
          information, stories of successes and hardships, and learn from one
          another. When no one else seems to understand, when pressure seems to
          be coming from all sides, and when parents need information and ideas
          from other parents, Sansarg hope to be the safe harbor in the midst of
          a storm.
        </p>
      </div>
    ),
  },
  {
    id: 19,
    title: "Physio Therapy",
    detailTitle: "PHYSIOTHERAPY",
    desc: "Physiotherapy intervention helps children to achieve their gross motor functions, enabling them to develop their physical independence in everyday activities so that they will be able to take their place within society.",
    color: "red",
    img: img9,
    detailImg: physio,
    about: (
      <div className="astitav_service-about_content_text-div">
        <p className="astitav_service-about_content_desc">
          The Physiotherapists assess, set goals and manage activity-based
          intervention, hydrotherapy, individual and group therapy to improve
          the child’s quality of life. Their goals include helping the child to
          acquire gross motor and play skills, pre-requisite skills for sports,
          physical fitness and socialization with their peers.
        </p>
        <p className="astitav_service-about_content_desc">
          To maximize the child’s development and potential, the
          Physiotherapists work closely with parents, teachers and fellow
          professionals, and collaborate with the various medical and allied
          health professionals.
        </p>
      </div>
    ),
  },
  {
    id: 20,
    title: "Psychology",
    detailTitle: "PSYCHOLOGY",
    desc: "The Psychologists are also involved in training parents and teachers on a variety of topics related to learning, emotion and social development skills, child development and classroom management skills.",
    color: "green",
    img: img10,
    detailImg: psyco,
    about: (
      <div className="astitav_service-about_content_text-div">
        <p className="astitav_service-about_content_desc">
          Consultation and intervention to address concerns related to
          behaviour, emotions, learning, social and group skills are provided to
          parents, caregivers, teachers and students. Challenging behaviour that
          can impede a child’s learning is also addressed.
        </p>
        <p className="astitav_service-about_content_desc">
          The Psychologists are also involved in training parents and teachers
          on a variety of topics related to learning, emotion and social
          development skills, child development and classroom management skills.
        </p>
        <p className="astitav_service-about_content_desc">
          To achieve holistic intervention, the Psychologists work closely with
          parents, caregivers, teachers and fellow allied health professionals.
        </p>
      </div>
    ),
  },

  {
    id: 21,
    title: "Others Services",
    detailTitle: "OTHER SERVICES",
    desc: "Astitav provides many other services for children with special needs like Parent / Teacher Training Workshop . Support Group, Counselling and Mother Child Program . We Believe that interacttion with parents is the best ways.",
    color: "orange",
    img: img13,
    about: (
      <div className="astitav_service-about_other-content_div">
        <div className="astitav_service-about_other-service-div">
          <p>Parent / Teacher Training Workshop</p>
          <img src={parentTeacher} alt="" />
        </div>
        <div className="astitav_service-about_other-service-div">
          <p>Parents Support Groups</p>
          <img src={parentSupport} alt="" />
        </div>
        <div className="astitav_service-about_other-service-div">
          <p>Counselling</p>
          <img src={counselling} alt="" />
        </div>
        <div className="astitav_service-about_other-service-div">
          <p>Siblings training programs</p>
          <img src={sibling} alt="" />
        </div>
        <div className="astitav_service-about_other-service-div">
          <p>Day Care</p>
          <img src={dayCare} alt="" />
        </div>
      </div>
    ),
  },
];

export default serviceData;
