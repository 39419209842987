import React from "react";
import "./B2B.css";
// import { BsUpload } from "react-icons/bs";

import Header from "../../common/Header/Header";
import BgCircles from "../../common/BgCircles/BgCircles";

const B2B = () => {
  return (
    <div>
      <Header
        title="Bridge to Brilliance"
        // paragraph="We look for potential which has a quality of a leader, to think and act for the benefit of all and moreover, become our strong pillar to implement projects in the sustainability domain"
      />
      <div className="astitav_b2b_content">
        <BgCircles />
        <div className="astitav_b2b_form">
          {/* <div className="astitav-history_container"> */}
          <div className="astitav-history_content">
            <h2 className="astitav-history_content-heading">OUR VISION</h2>
            <p className="astitav-history_content-paragraph">
              To create a brighter and more equitable future by providing
              quality education and holistic support to underprivileged
              children. We envision a world where every child, regardless of
              their socio-economic background, has the opportunity to unleash
              their full potential, build a solid foundation for lifelong
              learning, and contribute positively to society. Through our
              unwavering commitment to education, mentorship, and community
              engagement, we aspire to break the cycle of poverty and empower
              these young minds to become confident, compassionate, and capable
              individuals who drive positive change in their communities and
              beyond.
            </p>
          </div>
          <div className="astitav-history_content">
            <h2 className="astitav-history_content-heading">Our Campaigns</h2>
            <div>
              <h2>ROSHINI :</h2>
              <p className="astitav-history_content-paragraph">
                We aim to ensure that every individual in the lower
                socio-economic strata in Noida, has access to and receive a
                quality education. This concept recognizes education as a
                fundamental human right and a key driver of personal
                development, economic growth, social progress, and overall
                well-being.
              </p>
            </div>
            <br />

            <div>
              <h2>Stepping Stone :</h2>
              <p className="astitav-history_content-paragraph">
                In our quest for an equitable and compassionate society, we
                believe that education should be a cornerstone of inclusivity,
                where no one is left behind. This campaign champions the cause
                of inclusive education, striving to create a world where every
                learner, regardless of their economical background, or unique
                characteristics, is given the opportunity to thrive in regular
                schools. After training and preparation, we want to put our
                children in regular school under the 25% quota that is provided
                to the underprivileged children.
              </p>
            </div>

            <br />
            <div>
              <h2>Upliftment Unleashed :</h2>
              <p className="astitav-history_content-paragraph">
                In this campaign, we vow to educate children and adults about
                hygiene, health, food, legal rights, government policies,
                financial literacy and much more. Hosting workshops can
                stimulate economic and community development in minority
                regions. By offering workshops, we want to build the capacity of
                local individuals and organizations to address their own
                challenges independently. This can lead to sustainable
                development and long-term positive outcomes. By providing
                knowledge and skills in such workshops, can help individuals in
                minority regions improve their economic prospects and break the
                cycle of poverty.
              </p>
            </div>
            <br />

            <div>
              <h2>Bridge the GAP :</h2>
              <p className="astitav-history_content-paragraph">
                We want to organizes various mobile health checkup camps and
                disability assessments in the priority regions. Health checkups
                will result as preventive measures such as vaccinations,
                screenings, and health education. These measures can help
                prevent the spread of diseases and promote healthier lifestyles
                Disability assessments are essential for identifying individuals
                with disabilities and determining their specific needs and
                requirements. This information can help in providing appropriate
                support and services to individuals with disabilities in such
                areas. Offering health checkups and disability assessments
                empowers individuals in rural areas to take charge of their
                health. It gives them the information and resources they need to
                make informed decisions about their well-being.
              </p>
            </div>
            <br />
            <div>
              <h2>Skill Sprint :</h2>
              <p className="astitav-history_content-paragraph">
                We will be providing vocational training in priority regions as
                it is crucial for a variety of reasons. It can have a
                significant positive impact on the individuals, communities, and
                economies of these regions. Vocational training equips
                individuals with practical skills and knowledge that can lead to
                better employment opportunities and higher incomes. In rural
                areas where traditional job opportunities may be limited,
                vocational skills can empower individuals to become self-reliant
                entrepreneurs and contribute to local economic development. This
                can be particularly important for maintaining local expertise in
                traditional crafts, agriculture, and other sectors that are
                vital to rural life.
              </p>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default B2B;
