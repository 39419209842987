import React from "react";
import "./header.css";
import Image from "../../assets/images/Header/header_img.png";
import autism from "../../assets/images/downsyndrome.svg";

const Header = (props) => {
  return (
    <div className="astitav-container">
      <div className="astitav-container_header">
        <div className="astitav-container_header-content">
          <h2 className="astitav-container_header-content_heading">
            {props.title}
          </h2>
          <p className="astitav-container_header-content_paragraph">
            {props.paragraph}
          </p>
          <p className="astitav-container_header-content_paragraph">
            {props.para2}
          </p>
          <p className="astitav-container_header-content_paragraph">
            {props.school1}
          </p>
          <p className="astitav-container_header-content_paragraph">
            {props.school2}
          </p>
          <p className="astitav-container_header-content_paragraph">
            {props.school3}
          </p>
        </div>
        <div className="astitav-container_header-image">
          <img
            src={autism}
            className="astitav-container_header-image_bg-img"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
