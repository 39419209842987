import React from "react";
import Header from "../../common/Header/Header";
import BgCircles from "../../common/BgCircles/BgCircles";
import "./Volunteer.css";

const Volunteer = () => {
  return (
    <div>
      <Header
        title="Become a Volunteer"
        paragraph="Our volunteering opportunities give individual the chance to directly work towards betterment of the society where we plan field visits, campaigns, field activities that builds more strong relationships with the community."
      />

      <div className="astitav_internship_content">
        <BgCircles />
        <div className="astitav_internship_form">
          <p className="astitav_internship_form_desc">
            To request a volunteering activity with our organization, Please
            fill the below form:
          </p>
          <div className="astitav_internship_form_double-input-div">
            <input type="text" placeholder="Full Name" />
            <input type="text" placeholder="Email" />
          </div>
          <div className="astitav_internship_form_radio-div">
            <p className="astitav_internship_form_radio-div-title">Gender</p>
            <div className="astitav_internship_form_input-div">
              <input type="radio" />
              <label>Male</label>
            </div>
            <div className="astitav_internship_form_input-div">
              <input type="radio" />
              <label>Female</label>
            </div>
          </div>
          <div className="astitav_internship_form_double-input-div">
            <input type="text" placeholder="Mobile Number" />
            <input type="text" placeholder="Employment Status" />
          </div>
          <input type="text" placeholder="Qualification" />
          <input type="text" placeholder="How you want to help us?" />

          <button>Submit my information</button>
        </div>
      </div>
    </div>
  );
};

export default Volunteer;
