import React, { useEffect, useState } from "react";
import "./ServiceAbout.css";
import { useParams } from "react-router-dom";
import serviceData from "../../data/servicesData";

const ServiceAbout = () => {
  const { id } = useParams();

  const [data, setData] = useState();

  const getServiceDetail = () => {
    setData(serviceData.filter((item) => item.id === parseInt(id))[0]);
  };

  useEffect(() => {
    getServiceDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="astitav__service-about">
      {data?.id === 21 ? (
        <div>{data?.about}</div>
      ) : (
        <div>
          <div className="astitav_service-about_header">
            <p className="astitav_service-about_header-title">About</p>
            <div className="astitav_service-about_header-desc-div">
              {data?.id === 15 ? (
                <p className="astitav_service-about_header-small-title">
                  <span>{data?.detailTitle}</span>
                </p>
              ) : (
                <p className="astitav_service-about_header-small-title">
                  the <span>{data?.detailTitle}</span>
                </p>
              )}
              <p className="astitav_service-about_header-desc">{data?.desc}</p>
              <div className="astitav_service-about_header-btns">
                <button>Call us</button>
                <button>Directions</button>
              </div>
            </div>
          </div>
          <div className="astitav_service-about_content">
            {data?.about}
            <div className="astitav_service-about_content_img-div">
              <img src={data?.detailImg} alt="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceAbout;
