import React from "react";
import "./LandingHero.css";
import Navbar from "../../common/Navbar/Navbar";

import girl1 from "../../assets/images/landing/hero/girl1.png";
import girl2 from "../../assets/images/landing/hero/girl2.png";
import boy from "../../assets/images/landing/hero/boy.png";
import circle from "../../assets/images/landing/hero/circle.svg";
import graphic from "../../assets/images/landing/hero/graphic.svg";

const LandingHero = () => {
  return (
    <div className="astitav_landing-hero">
      <Navbar />
      <div className="astitav_landing_hero_content">
        <img className="astitav_landing_hero_girl1" src={girl1} alt="" />
        <img className="astitav_landing_hero_graphic" src={graphic} alt="" />
        <div className="astitav_landing_hero_cream-circle"></div>
        <div className="astitav_landing_hero_purple-circle"></div>
        <div className="astitav_landing_hero_red-circle"></div>
        <div className="astitav_landing_hero_aqua-circle"></div>
        <div className="astitav_landing_hero_center-div">
          <img
            className="astitav_landing_hero_center-circle"
            src={circle}
            alt=""
          />
          <img
            className="astitav_landing_hero_center-girl2"
            src={girl2}
            alt=""
          />
        </div>
        <img className="astitav_landing_hero_boy" src={boy} alt="" />
      </div>
    </div>
  );
};

export default LandingHero;
