import React from "react";
import "./Internship.css";
import { BsUpload } from "react-icons/bs";

import Header from "../../common/Header/Header";
import BgCircles from "../../common/BgCircles/BgCircles";

const Internship = () => {
  return (
    <div>
      <Header
        title="Internship Form"
        paragraph="We look for potential which has a quality of a leader, to think and act for the benefit of all and moreover, become our strong pillar to implement projects in the sustainability domain"
      />
      <div className="astitav_internship_content">
        <BgCircles />
        <div className="astitav_internship_form">
          <p className="astitav_internship_form_desc">
            To join our team for Internship opportunities please share the
            following details with us.
          </p>
          <div className="astitav_internship_form_double-input-div">
            <input type="text" placeholder="Full Name" />
            <input type="text" placeholder="Email" />
          </div>
          <div className="astitav_internship_form_radio-div">
            <p className="astitav_internship_form_radio-div-title">Gender</p>
            <div className="astitav_internship_form_input-div">
              <input type="radio" />
              <label>Male</label>
            </div>
            <div className="astitav_internship_form_input-div">
              <input type="radio" />
              <label>Female</label>
            </div>
          </div>
          <div className="astitav_internship_form_double-input-div">
            <input type="text" placeholder="Mobile Number" />
            <input type="text" placeholder="Internship Period" />
          </div>
          <input type="text" placeholder="Area of Interest" />
          <div className="astitav_internship_form_resume-div">
            <p>Upload your Resume</p>
            <input type="file" id="file_input" name="file_input" />
            <label
              htmlFor="file_input"
              className="astitav_internship_form_resume-upload-btn-div"
            >
              <BsUpload className="astitav_internship_form_resume-upload-icon" />{" "}
              Upload file here...
            </label>
          </div>
          <button>Submit my information</button>
        </div>
      </div>
    </div>
  );
};

export default Internship;
