import React, { useEffect, useState } from "react";
import "./ServiceDetail.css";
import ServiceAbout from "../../components/ServiceAbout/ServiceAbout";
import { useParams } from "react-router-dom";
import serviceData from "../../data/servicesData";

const ServiceDetail = () => {
  const { id } = useParams();

  const [data, setData] = useState();

  const getServiceDetail = () => {
    setData(serviceData.filter((item) => item.id === parseInt(id))[0]);
  };

  useEffect(() => {
    getServiceDetail();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="astitav_service-detail_header">
        <p className="astitav_service-detail_title">{data?.detailTitle}</p>
        <p className="astitav_service-detail_desc">
          Your child will be given you the most important information, an
          exceptional place for your child to thrive and grow.
        </p>
      </div>
      <ServiceAbout />
    </div>
  );
};

export default ServiceDetail;
