import React from "react";
import "./VideoFrame.css";

import tvFrame from "../../assets/images/landing/believe/tvscreen.svg";

const VideoFrame = (props) => {
  return (
    <div className="astitav_believe_video-container">
      <img className="astitav_believe_tvframe" src={tvFrame} alt="" />
      <video className="astitav_believe_video" src={props.video} controls />
    </div>
  );
};

export default VideoFrame;
