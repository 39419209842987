import React from "react";
import "./VisitUs.css";

const VisitUs = (props) => {
  return (
    <div className="astitav_visit-us">
      <p className="astitav_visit-us_title">Come</p>
      <div className="astitav_visit-us_content">
        <div className="astitav_visit-us_first_div">
          <p className="astitav_visit-us_desc1">and visit us.</p>
          <p className="astitav_visit-us_desc2">
            Tell us a little about your family to get started. Come learn about
            the Astitav experience!
          </p>
          <div className="astitav_visit-us_contact-form">
            <div className="astitav_visit-us_contact-form_flex-div">
              <input
                className="astitav_visit-us_input"
                type="text"
                placeholder="First Name"
              />
              <input
                className="astitav_visit-us_input"
                type="text"
                placeholder="Last Name"
              />
            </div>
            <input
              className="astitav_visit-us_input"
              type="text"
              placeholder="Email"
            />
            <div className="astitav_visit-us_contact-form_flex-div">
              <input
                className="astitav_visit-us_input"
                type="text"
                placeholder="Zip Code"
              />
              <input
                className="astitav_visit-us_input"
                type="text"
                placeholder="Phone Number"
              />
            </div>
            <input
              className="astitav_visit-us_input"
              type="text"
              placeholder="When you want to start?"
            />
            <div className="astitav_visit-us_contact-form_date-time-div">
              <input
                className="astitav_visit-us_input"
                type="text"
                placeholder="Date"
              />
              <input
                className="astitav_visit-us_input"
                type="text"
                placeholder="Time"
              />
            </div>
            <textarea
              className="astitav_visit-us_input"
              //   cols="30"
              rows="4"
              placeholder="Is there anything we should know about your family?"
            />
            <button className="astitav_visit-us_btn">
              Submit my information
            </button>
          </div>
        </div>
        <div className="astitav_visit-us_second-div">
          <img className="astitav_visit-us_people-img" src={props.img} alt="" />
          <div className="astitav_visit-us_contact-div">
            <div className="astitav_visit-us_contact-type-div">
              <p className="astitav_visit-us_contact-title">Address</p>
              <p className="astitav_visit-us_contact-desc">
                HA-09, Radhika Enclave, Sector 104,
                <br />
                Noida, Uttar Pradesh, India,
                <br />
                Pin Code :- 201304
              </p>
            </div>
            <div className="astitav_visit-us_contact-type-div">
              <p className="astitav_visit-us_contact-title">Phone</p>
              <p className="astitav_visit-us_contact-desc">
                0120-4970578, +91-9911432662
              </p>
            </div>
            <div className="astitav_visit-us_contact-type-div">
              <p className="astitav_visit-us_contact-title">Email</p>
              <p className="astitav_visit-us_contact-desc">
                astitav.ace@gmail.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitUs;
