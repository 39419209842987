import React from 'react'
import Header from '../../common/Header/Header';
import Advisor from '../../components/Advisor/Advisor';

const Advisors = () => {
  return (
<div>
    <Header 
    title="Advisor Board members"
    paragraph="Our professional teachers love learning and pass that love and excitement to the children."
  />
  <Advisor />
</div>
  )
}

export default Advisors