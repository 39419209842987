import React from "react";
import "./Parents.css";

import VisitUs from "../../components/VisitUs/VisitUs";
import corn from "../../assets/images/visitus/corn.svg";

import payment from "../../assets/images/parents/paymenrt.svg";
// import bottle from "../../assets/images/parents/bottle.svg";
import brochure from "../../assets/images/parents/brochure.svg";
import calender from "../../assets/images/parents/calender.svg";
// import direction from "../../assets/images/parents/direction.svg";
import list from "../../assets/images/parents/list.svg";
import reporting from "../../assets/images/parents/reporting.svg";
import school from "../../assets/images/parents/school.svg";

import cactus from "../../assets/images/parents/cactus.png";

import { BiMoneyWithdraw } from "react-icons/bi";
import { FaPeopleArrows } from "react-icons/fa";

const Parents = () => {
  const cardsData = [
    {
      id: 1,
      title: "Payments",
      desc: "Payments can be made by upi, cash or cheque made payable to Astitav School.",
      img: payment,
    },
    {
      id: 2,
      title: "School Readiness",
      desc: "Carefully designed by our educators to support all your child’s exciting.",
      img: school,
    },
    {
      id: 3,
      title: "School Brochure",
      desc: "You can simply click on the button to download the latest 2023-2024 brochure.",
      img: brochure,
    },

    {
      id: 4,
      title: "Fees",
      desc: "Fees should be deposited in advanced.",
      icon: <BiMoneyWithdraw style={{ fontSize: "3.5rem", color: "purple" }} />,
    },
    {
      id: 5,
      img: cactus,
    },
    {
      id: 6,
      title: "1-1",
      desc: "One-on-one session will be chargable in case of absence.",
      icon: <FaPeopleArrows style={{ fontSize: "3.5rem", color: "purple" }} />,
    },
    {
      id: 7,
      title: "Opening Hours",
      desc: "The school office to parents from 8:30 am to 4:00pm every school day.",
      img: calender,
    },
    {
      id: 8,
      title: "Child Care List",
      desc: "Carefully designed by our educators to support all your child’s exciting.",
      img: list,
    },
    {
      id: 9,
      title: "Reporting Absence",
      desc: "Pre-information about absence will be appericiated",
      img: reporting,
    },
  ];

  return (
    <div className="astitav_parents">
      <p className="astitav_parents_title">For Our Parents</p>
      <div className="astitav_parents_cards-div">
        {cardsData.map((item) => (
          <div
            className={`${
              item.id === 5
                ? "astitav_parents_card-cactus-img"
                : "astitav_parents_card"
            }`}
          >
            <img src={item.img} alt="" />
            {item.icon}
            <p className="astitav_parents_card-title">{item.title}</p>
            <p className="astitav_parents_card-desc">{item.desc}</p>
          </div>
        ))}
      </div>
      <VisitUs img={corn} />
    </div>
  );
};

export default Parents;
