import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

import bg from "../../assets/images/footer/bg.svg";
import bgDesktop from "../../assets/images/footer/bgDesktop.svg";
import bear from "../../assets/images/footer/bear.svg";
import tree from "../../assets/images/footer/tree.svg";
import autism from "../../assets/images/autism.jpg";
import puzzle from "../../assets/images/puzzle.png";

import { BsFacebook, BsLinkedin } from "react-icons/bs";
import { FiInstagram } from "react-icons/fi";

const Footer = () => {
  return (
    <div className="astitav_footer">
      <img className="astitav_footer_bg" src={bg} alt="" />
      <img className="astitav_footer_bg-desktop" src={bgDesktop} alt="" />
      <div className="astitav_footer_graphics_div">
        <img className="astitav_footer_tree-sm" src={puzzle} alt="" />
        <img className="astitav_footer_bear" src={autism} alt="" />
        <img className="astitav_footer_tree-lg" src={puzzle} alt="" />
      </div>
      <div className="astitav_footer_center-div">
        <div className="astitav_footer_links">
          <div className="astitav_footer_links_list">
            <Link to="/admission">Admission</Link>
            <Link to="/contact-us">Contact</Link>
          </div>
          <div className="astitav_footer_links_list">
            <a
              href="https://maps.app.goo.gl/iiGJwHSwDqCbTgwf6"
              target="_blank"
              rel="noreferrer"
            >
              Location
            </a>
            <Link to="/donation">Donation</Link>
          </div>
        </div>
        <p className="astitav_footer_logo">Astitav</p>
        <div className="astitav_footer_social-links-div">
          <a
            className="astitav_footer_btn"
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send?phone=919911432662"
          >
            Book a Visit
          </a>

          <div className="astitav_footer_social-div">
            <a
              href="https://www.facebook.com/astitav.ace.noida"
              target="_blank"
              rel="noreferrer"
            >
              <BsFacebook className="astitav_footer_fb-logo" />
            </a>
            <a
              href="https://instagram.com/astitav.ace?igshid=MzRlODBiNWFlZA=="
              target="_blank"
              rel="noreferrer"
            >
              <FiInstagram className="astitav_footer_ig-logo" />
            </a>
            <a
              href="https://www.linkedin.com/company/astitav"
              target="_blank"
              rel="noreferrer"
            >
              <BsLinkedin className="astitav_footer_ld-logo" />
            </a>
          </div>
        </div>
      </div>
      <p className="astitav_footer_copyright-text">
        © 2023 . All Rights Reserved . Astitiav
      </p>
    </div>
  );
};

export default Footer;
