import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./Navbar.css";
import { AiOutlineMenu } from "react-icons/ai";
import { BsXLg } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import Logo from "../../assets/images/logo.svg";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [aboutDrop, setAboutDrop] = useState(false);
  const [admissionDrop, setAdmissionDrop] = useState(false);

  return (
    <div className="astitav_navbar">
      <div className="astitav_navbar-links">
        <Link to="/" className="astitav_navbar_logo">
          <img src={Logo} alt="" />
        </Link>
        <div className="astitav_navbar-links_container">
          <li className="astitav_navbar-links_li">
            <NavLink className="astitav_navbar_navlinks" to="/">
              Home
            </NavLink>
          </li>

          <div
            className="astitav_nav_dropdown"
            onMouseEnter={() => setAboutDrop(true)}
            onMouseLeave={() => setAboutDrop(false)}
          >
            <li className="astitav_navbar-links_li">
              About Us
              {aboutDrop ? (
                <IoIosArrowUp style={{ fontSize: "1rem" }} />
              ) : (
                <IoIosArrowDown style={{ fontSize: "1rem" }} />
              )}
            </li>
            <div className="astitav_nav_dropdown-content">
              <li
                className="astitav_navbar-links_li"
                style={{
                  color: "red",
                  margin: "0 auto 0 0.9rem",
                  padding: "0 0.5rem",
                  textAlign: "start",
                  borderRadius: "0.3rem",
                  border: "2px solid red",
                }}
              >
                <NavLink to="/donation">Donation</NavLink>
              </li>
              <li className="astitav_navbar-links_li">
                <NavLink to="/about-us">About Us</NavLink>
              </li>
              <li className="astitav_navbar-links_li">
                <NavLink to="/internship">Internship</NavLink>
              </li>
              <li className="astitav_navbar-links_li">
                <NavLink to="/volunteer">Volunteer</NavLink>
              </li>
              <li className="astitav_navbar-links_li">
                <NavLink to="/founder">Founder's Message</NavLink>
              </li>
              <li className="astitav_navbar-links_li">
                <NavLink to="/advisor">Advisors</NavLink>
              </li>
              <li className="astitav_navbar-links_li">
                <NavLink to="/astitav_in_schools">Astitav in Schools</NavLink>
              </li>
            </div>
          </div>
          <li className="astitav_navbar-links_li">
            <NavLink
              className="astitav_navbar_navlinks"
              to="/bridge-to-brilliance"
            >
              B2B
            </NavLink>
          </li>
          <li className="astitav_navbar-links_li">
            <NavLink className="astitav_navbar_navlinks" to="/our-gallery">
              Gallery
            </NavLink>
          </li>

          <div
            className="astitav_nav_dropdown"
            onMouseEnter={() => setAdmissionDrop(true)}
            onMouseLeave={() => setAdmissionDrop(false)}
          >
            <li className="astitav_navbar-links_li">
              Admission
              {admissionDrop ? (
                <IoIosArrowUp style={{ fontSize: "1rem" }} />
              ) : (
                <IoIosArrowDown style={{ fontSize: "1rem" }} />
              )}
            </li>
            <div className="astitav_nav_dropdown-content">
              <li className="astitav_navbar-links_li">
                <NavLink to="/admission">Admission</NavLink>
              </li>
              <li className="astitav_navbar-links_li">
                <NavLink to="/how-to-apply">How to apply</NavLink>
              </li>
              <li className="astitav_navbar-links_li">
                <NavLink to="/for-our-parents">For our parents</NavLink>
              </li>
            </div>
          </div>

          <li className="astitav_navbar-links_li">
            <NavLink className="astitav_navbar_navlinks" to="/services">
              Services
            </NavLink>
          </li>
          <li className="astitav_navbar-links_li">
            <NavLink className="astitav_navbar_navlinks" to="/contact-us">
              Contact Us
            </NavLink>
          </li>
          <li className="astitav_navbar-links_li_btn">
            <a
              className="astitav_navbar_navlinks"
              target="_blank"
              rel="noreferrer"
              href="https://api.whatsapp.com/send?phone=919911432662"
            >
              Book a Visit
            </a>
          </li>
        </div>
      </div>
      <div className="astitav_navbar-menu">
        {toggleMenu ? (
          <BsXLg
            className="astitav_navbar-menu_toggleBtn"
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <AiOutlineMenu
            className="astitav_navbar-menu_toggleBtn"
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="astitav_navbar-menu_container">
            <div className="astitav_navbar-menu_container-links">
              <li onClick={() => setToggleMenu(false)}>
                <NavLink className="astitav_navbar_navlinks" to="/">
                  Home
                </NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink className="astitav_navbar_navlinks" to="/about-us">
                  About Us
                </NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink
                  className="astitav_navbar_navlinks"
                  to="/bridge-to-brilliance"
                >
                  B2B
                </NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink className="astitav_navbar_navlinks" to="/our-gallery">
                  Our Gallery
                </NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink className="astitav_navbar_navlinks" to="/services">
                  Services
                </NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink className="astitav_navbar_navlinks" to="/admission">
                  Admission
                </NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink className="astitav_navbar_navlinks" to="/how-to-apply">
                  How to apply
                </NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink
                  className="astitav_navbar_navlinks"
                  to="/for-our-parents"
                >
                  For our parents
                </NavLink>
              </li>

              <li onClick={() => setToggleMenu(false)}>
                <NavLink className="astitav_navbar_navlinks" to="/internship">
                  Internship
                </NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink className="astitav_navbar_navlinks" to="/volunteer">
                  Voulnteer
                </NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink className="astitav_navbar_navlinks" to="/founder">
                  Founder's Message
                </NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink className="astitav_navbar_navlinks" to="/advisor">
                  Advisors
                </NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink
                  className="astitav_navbar_navlinks"
                  to="/astitav_in_schools"
                >
                  Astitav in Schools
                </NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink className="astitav_navbar_navlinks" to="/contact-us">
                  Contact Us
                </NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <a
                  className="astitav_navbar_navlinks"
                  target="_blank"
                  rel="noreferrer"
                  href="https://api.whatsapp.com/send?phone=919911432662"
                >
                  Book a Visit
                </a>
              </li>
              <li
                onClick={() => setToggleMenu(false)}
                style={{
                  color: "red",
                  margin: "0 0rem 0 auto",
                  padding: "0.2rem 0.6rem",
                  textAlign: "end",
                  borderRadius: "0.3rem",
                  border: "2px solid red",
                  width: "fit-content",
                }}
              >
                <NavLink className="astitav_navbar_navlinks" to="/donation">
                  Donation
                </NavLink>
              </li>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
