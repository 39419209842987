import React from "react";
// import Bg from '../../assets/images/About/bg.png';
import Videoimg from "../../assets/images/About/History/videoimg.png";
import "./history.css";

const History = () => {
  return (
    <div className="astitav-history_container">
      <div className="astitav-history">
        <div className="astitav-history_image">
          <img src={Videoimg} className="astitav-history_image-img" alt="" />
        </div>
        <div className="astitav-history_content">
          <h2 className="astitav-history_content-heading">Our Vision</h2>
          <p className="astitav-history_content-paragraph">
            Our vision is to empower the Children with Special Need (CWSN)
            through knowledge, health care, education, vocational training.
          </p>
        </div>
      </div>
    </div>
  );
};

export default History;
