import React, { useEffect } from "react";
import Header from "../../common/Header/Header";
// import KeyDates from "../../components/KeyDates/KeyDates";
// import Elementary from "../../components/Elementary/Elementary";
import Downloads from "../../components/Downloads/Downloads";
import AdmissionSteps from "../../components/AdmissionSteps/AdmissionSteps";

const Admission = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header
        title="Admission"
        paragraph="We are gonna help you – Just read the information down below."
      />
      {/* <KeyDates /> */}
      {/* <Elementary /> */}
      <Downloads />
      <AdmissionSteps />
    </div>
  );
};

export default Admission;
