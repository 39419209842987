import React from "react";
import Header from "../../common/Header/Header";
import Message from "../../components/FounderMessage/Message";

const Founder = () => {
  return (
    <div>
      <Header
        title="Founder’s Message"
        paragraph="Our professional teachers love learning and pass that love and excitement to the children."
      />
      <Message />
    </div>
  );
};

export default Founder;
