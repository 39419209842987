import React from "react";
import Whypic from "../../assets/images/About/History/whypic.png";
import "./why.css";

const Why = () => {
  return (
    <div className="astitav-about-why_container">
      <div className="astitav-about-why_container-card">
        <div className="astitav-about-why_container-card_content">
          <h2 className="astitav-about-why_container-card_content-heading">
            Our Mission
          </h2>
          <h3
            className="astitav-about-why_container-card_content-paragraph"
            style={{ fontWeight: "bold" }}
          >
            We are committed to enhance the quality of life of person with
            disabilities and their families through:
          </h3>
          <p className="astitav-about-why_container-card_content-paragraph">
            • Providing person-centric programmes and services to enable them to
            achieve their fullest potential.
          </p>
          <p className="astitav-about-why_container-card_content-paragraph">
            • Leading innovation and developing capabilities that better support
            their needs and aspirations.
          </p>
          <p className="astitav-about-why_container-card_content-paragraph">
            • Advocating and engaging partners and the public to co-create an
            inclusive community.
          </p>
          <h3
            className="astitav-about-why_container-card_content-paragraph"
            style={{ fontWeight: "bold" }}
          >
            Core Values
          </h3>
          <p className="astitav-about-why_container-card_content-paragraph">
            We respect the intrinsic and unique worth of every individual, and
            serve with compassion and dedication to discover, build, and
            celebrate the ability of each person. We act with integrity and
            achieve through the synergy of teamwork between staff, families, and
            the communities that support our mission. We pursue innovation to
            strive for excellence in services for persons with disabilities, to
            maximise potential and create opportunities to thrive in community.
          </p>
        </div>
      </div>
      <div className="astitav-about-why_container-image">
        <img
          src={Whypic}
          className="astitav-about-why_container-image-img"
          alt=""
        />
      </div>
    </div>
  );
};

export default Why;
