import React from "react";
import "./Therapies.css";

import people from "../../assets/images/landing/therapies/people.svg";
import aba from "../../assets/images/landing/therapies/aba.svg";
import behaviour from "../../assets/images/landing/therapies/behaviour.svg";
import language from "../../assets/images/landing/therapies/language.svg";
import physiotherapy from "../../assets/images/landing/therapies/physiotherapy.svg";
import sensory from "../../assets/images/landing/therapies/sensory.svg";
import speech from "../../assets/images/landing/therapies/speech.svg";
import { Link } from "react-router-dom";

const Therapies = () => {
  return (
    <div className="astitav_therapies">
      <p className="astitav_therapies_title">All</p>
      <div className="astitav_therapies_content">
        <div className="astitav_therapies_list-div">
          <div className="astitav_therapy_div">
            <img src={behaviour} alt="" />
            <p>Behaviour Modifications</p>
          </div>
          <div className="astitav_therapy_div">
            <img src={aba} alt="" />
            <p>ABA therapy</p>
          </div>
          <div className="astitav_therapy_div">
            <img src={physiotherapy} alt="" />
            <p>Physiotherapy</p>
          </div>
          <div className="astitav_therapy_div">
            <img src={language} alt="" />
            <p>Language development therapy</p>
          </div>
          <div className="astitav_therapy_div">
            <img src={speech} alt="" />
            <p>Speech therapy</p>
          </div>
          <div className="astitav_therapy_div">
            <img src={sensory} alt="" />
            <p>Sensory Integration therapy</p>
          </div>
        </div>
        {/* ///Therapies div desktop---- */}
        <div className="astitav_therapies_list-div-desktop">
          <div className="astitav_therapies_div-group">
            <div className="astitav_therapy_div">
              <img src={aba} alt="" />
              <p>ABA therapy</p>
            </div>
            <div className="astitav_therapy_div">
              <img src={language} alt="" />
              <p>Language development therapy</p>
            </div>
          </div>
          <div className="astitav_therapies_div-group">
            <div className="astitav_therapy_div">
              <img src={behaviour} alt="" />
              <p>Behaviour Modifications</p>
            </div>
            <img className="astitav_therapies_people-img" src={people} alt="" />
            <div className="astitav_therapy_div">
              <img src={speech} alt="" />
              <p>Speech therapy</p>
            </div>
          </div>
          <div className="astitav_therapies_div-group">
            <div className="astitav_therapy_div">
              <img src={physiotherapy} alt="" />
              <p>Physiotherapy</p>
            </div>
            <div className="astitav_therapy_div">
              <img src={sensory} alt="" />
              <p>Sensory Integration therapy</p>
            </div>
          </div>
        </div>
        <div className="astitav_therapies_desc1_div">
          <p className="astitav_therapies_desc1">therapies under one roof</p>
          <div className="astitav_therapies_desc-div">
            <p className="astitav_therapies_desc2">
              We are catering the needs of children with Autism, ATHD, learning
              disabilities and other related different needs.
            </p>
            <Link to="/services" className="astitav_therapies_btn">
              Program
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Therapies;
