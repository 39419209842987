import React from "react";
import "./Downloads.css";
import checklistImg from "../../assets/images/downloads/checklist.svg";
import casestudyImg from "../../assets/images/downloads/caseStudy.svg";
import grade1formImg from "../../assets/images/downloads/grade1form.svg";
import grade2formImg from "../../assets/images/downloads/grade2-6.svg";
import preschoolImg from "../../assets/images/downloads/preschool.svg";
import BgCircles from "../../common/BgCircles/BgCircles";

const Downloads = () => {
  const downloadData = [
    {
      id: 1,
      title: "Elementary School Admissions Checklist",
      img: checklistImg,
    },
    {
      id: 2,
      title: "Case Study",
      img: casestudyImg,
    },
    {
      id: 3,
      title: "Kindergarten & Grade 1 Student Evaluation Form",
      img: grade1formImg,
    },
    {
      id: 4,
      title: "Grade 2-6 Student Evaluation Form",
      img: grade2formImg,
    },
    {
      id: 5,
      title: "Preschool Admission Checklist",
      img: preschoolImg,
    },
  ];

  return (
    <div className="astitav_admission_downloads">
      <BgCircles />
      {downloadData.map((item) => (
        <div key={item.id} className="astitav_admission_download-card">
          <img src={item.img} alt="" />
          <p className="astitav_admission_download-card_title">{item.title}</p>
          <p className="astitav_admission_download-btn">Download</p>
        </div>
      ))}
    </div>
  );
};

export default Downloads;
