import React from "react";
import "./Gallery.css";

import pic1 from "../../assets/images/gallery/pic1.png";
import pic2 from "../../assets/images/gallery/pic2.png";
import pic3 from "../../assets/images/gallery/pic3.png";
import pic4 from "../../assets/images/gallery/pic4.png";
import pic5 from "../../assets/images/gallery/pic5.png";
import pic6 from "../../assets/images/gallery/pic6.png";
import pic7 from "../../assets/images/gallery/pic7.png";
import pic8 from "../../assets/images/gallery/pic8.png";
import pic9 from "../../assets/images/gallery/pic9.png";
import pic10 from "../../assets/images/gallery/pic10.png";
import pic11 from "../../assets/images/gallery/pic11.png";
import pic12 from "../../assets/images/gallery/pic12.png";
import pic13 from "../../assets/images/gallery/pic13.png";
import pic14 from "../../assets/images/gallery/pic14.png";
import pic15 from "../../assets/images/gallery/pic15.png";
import pic16 from "../../assets/images/gallery/pic16.png";
import pic17 from "../../assets/images/gallery/pic17.png";
import pic18 from "../../assets/images/gallery/pic18.png";
import pic19 from "../../assets/images/gallery/pic19.png";
import pic20 from "../../assets/images/gallery/pic20.png";
import pic21 from "../../assets/images/gallery/pic21.png";
import pic22 from "../../assets/images/gallery/pic22.png";
import pic23 from "../../assets/images/gallery/pic23.png";
import pic24 from "../../assets/images/gallery/pic24.png";
import pic25 from "../../assets/images/gallery/pic25.png";
import pic26 from "../../assets/images/gallery/pic26.png";

const Gallery = () => {
  return (
    <div className="astitav_gallery">
      <p className="astitav_gallery_title">Our Gallery</p>
      <div className="astitav_gallery_images-div">
        <img src={pic1} alt="" />
        <img src={pic2} alt="" />
        <img src={pic3} alt="" />
        <img src={pic4} alt="" />
        <img src={pic5} alt="" />
        <img src={pic6} alt="" />
        <img src={pic7} alt="" />
        <img src={pic8} alt="" />
        <img src={pic9} alt="" />
        <img src={pic10} alt="" />
        <img src={pic11} alt="" />
        <img src={pic12} alt="" />
        <img src={pic13} alt="" />
        <img src={pic14} alt="" />
        <img src={pic15} alt="" />
        <img src={pic16} alt="" />
        <img src={pic17} alt="" />
        <img src={pic18} alt="" />
        <img src={pic19} alt="" />
        <img src={pic20} alt="" />
        <img src={pic21} alt="" />
        <img src={pic22} alt="" />
        <img src={pic23} alt="" />
        <img src={pic24} alt="" />
        <img src={pic25} alt="" />
        <img src={pic26} alt="" />
      </div>
    </div>
  );
};

export default Gallery;
